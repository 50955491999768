module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"schema":{"perPage":50,"requestConcurrency":10,"previewRequestConcurrency":3,"timeout":120000,"queryDepth":15,"circularQueryLimit":5,"typePrefix":"Wp"},"url":"https://wp.disruptiveadvertising.com/graphql","verbose":true,"html":{"createStaticFiles":false,"useGatsbyImage":false,"imageMaxWidth":null,"fallbackImageMaxWidth":1024,"imageQuality":70,"generateWebpImages":true,"generateAvifImages":false,"placeholderType":"dominantColor"},"develop":{"hardCacheMediaFiles":true,"nodeUpdateInterval":5000,"hardCacheData":false},"type":{"Post":{"limit":null},"MediaItem":{"placeholderSizeName":"gatsby-image-placeholder","createFileNodes":true,"lazyNodes":false},"Page":{"limit":null},"Comment":{"limit":0},"ShowBio":{"limit":null},"Tag":{"limit":null},"User":{"limit":null},"RootQuery":"{ excludeFieldNames: ['viewer', 'node', 'schemaMd5'], },"},"production":{"allow404Images":true,"hardCacheMediaFiles":false,"allow401Images":false},"catchLinks":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Disruptive Advertising","short_name":"Disruptive","start_url":"/","background_color":"#FFFFFF","theme_color":"#f87171","display":"minimal-ui","icon":"src/assets/images/disruptiveLogo.png","icons":[{"src":"src/assets/images/maskableIcon.png","sizes":"192x192","type":"image/png","purpose":"maskable"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d956a50b11618060a8c21b6ca10ced18"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://disruptiveadvertising.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-T3TGDCV","includeInDevelopment":true,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby","environment":"production","branch":"master","commit_ref":"ab2eee1df5e5e98d39da075c74fe66220f8ca41e","deploy_id":"67ef22f5273771000849b59e"}},"routeChangeEventName":"ROUTE_CHANGE","enableWebVitalsTracking":true,"selfHostedOrigin":"https://www.googletagmanager.com","selfHostedPath":"gtm.js"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
